@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Montserrat', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Montserrat', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Montserrat', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Montserrat', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Montserrat', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Montserrat', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#290789;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Montserrat', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color: #333;
    box-shadow: 0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 200px; /*LM modified from 250px*/
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Montserrat', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #49256e; 
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #49256e;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
    opacity: 1; /*LM - added opacity so its shown properly*/
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 3px; /*LM 8px to 3px*/
	left: 0; /*LM -15px to 0*/
	font-size: 18px;
    color:#fff; /*LM - added the color*/
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #000;
    /*LM*/
	font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.7px;
	font-family: 'Montserrat', sans-serif;
}
.links > li > a:hover {
	background-color: #290789; /*LM - modified the color*/
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #290789;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #290789;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #290789;
}
.mobnav-trigger.active {
	background-color: #290789;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #000;
}
#nav .nav-item--home .fa-home {
	color: #290789 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #290789;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #290789;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #290789;
	border-right-color: #290789;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #000 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #e0e0e0 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #000;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #e0e0e0 !important;
    color:#000 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #43266d;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #43266d;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #fff; /*LM - modified from #ebebeb*/
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
}


/************************** MICS **************************/

/*LM:start*/
#footer .footer-top-container .section.clearer{
     border-top:6px solid #290789;
     padding:0;
}
#footer .policy-column, #footer .contact-us-column {
     margin-top:50px;
}
#footer .policy-column li {
     display:inline;
     margin-right:20px;
}
/*Add padding to the columns of the footer and reduce it for specific widths*/
#footer .content-div{
     padding-left:40%
}
@media only screen and (max-width:1499px) {
     #footer .content-div{
         padding-left:30% 
    }
}
@media only screen and (max-width:1199px) {
     #footer .content-div{
         padding-left:20% 
    }
}
@media only screen and (max-width:959px) {
     #footer .content-div {
         padding-left:0;
    }
}
/*Style the fa icons*/
#footer .fa {
     color:#290789;
     margin-right:10px;
}
/*Copyright*/
.footer-bottom-container {
     background-color:#e0e0e0 !important;
}
.footer-bottom-container p.footer-copyright {
     color:#000;
}
.footer-bottom-container .item.item-left {
     float:none;
     text-align:center;
}
/*Reduce the logo size for mobile and tablet*/
@media only screen and (max-width:959px) {
     #footer .logo img {
         max-width:100px;
    }
     #footer .policy-column, #footer .contact-us-column {
         margin-top:20px;
    }
}
/*Tablet specific*/
@media only screen and (min-width:768px) and (max-width:959px) {
     #footer .policy-column {
         margin-left:50px;
    }
     #footer .contact-us-column {
         margin-left:30px;
    }
}
/*Mobile specific*/
@media only screen and (max-width:767px) {
     #footer .module-logo {
         text-align:center;
    }
     #footer .policy-column, #footer .contact-us-column {
         margin-left:10px;
    }
     #footer .contact-us-column {
         margin-bottom:20px;
    }
     #footer .footer-top-container {
         overflow-x:hidden;
    }
}
/*Footer:ends*/
/*Header*/
/*Set font weight for text on the top header*/
.links > li > a, .userCode, .userName, .welcome-msg, .welcome-msg *, .userSwitch a {
     font-weight:700;
}
/*All fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Remove the hover of the Switch Customer text*/
.userSwitch:hover a {
     color:#fff;
}
/*Set the hover color of the caret*/
#mini-cart:hover .caret {
     border-top-color:#fff;
     opacity:1;
}
/*Vertically align user links on the top header*/
.links>li, .links>li>a {
     line-height:1.3em;
}
/*Add padding to the top header - only for desktops*/
@media only screen and (min-width:960px) {
     .header-top-container .header-top {
         padding-top:10px;
    }
}
/*Sticky header*/
#header {
     position:sticky;
     top:0;
     z-index:50;
}
/*color of the cart subtotal*/
@media only screen and (min-width:768px) {
     #mini-cart .dropdown-toggle .price span {
         color:#fff;
    }
}
/*Change the text Switch Customer to icon for devices less than 1440px*/
@media only screen and (max-width:1440px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:18px;
         text-indent:0;
         float:left;
    }
     /*Also reduce the font size*/
     .welcome-msg *, .userCode:not(.fa-bars), .userName{
         font-size:11px !important;
    }
     .module-user-links ul li > a {
         font-size:14px !important;
    }
     /*shift the switch customer code/name/icon slightly down to align with the welcome message*/
     .userCode:not(.fa-bars), .userName, .userSwitch {
         margin-top:2px;
    }
}
/*Reduce the font-size for user links for smallr desktops*/
@media only screen and (min-width:960px) and (max-width:1199px) {
     .module-user-links ul li > a {
         font-size:12px !important;
    }
}
/*Change the color of the user links to white*/
.item-user-links li a {
     color:#fff;
}
/*Hide the home icon*/
.nav-item.nav-item--home {
     display:none;
}
/*Placeholder text*/
::placeholder {
     color:#000;
     letter-spacing:0.5px;
     opacity:1;
     font-weight:700;
     font-size:14px;
     /* Firefox */
}
:-ms-input-placeholder {
     /* Internet Explorer 10-11 */
     color:#000;
     letter-spacing:0.5px;
}
::-ms-input-placeholder {
     /* Microsoft Edge */
     color:#000;
     letter-spacing:0.5px;
}
/*Background of the top header*/
.desktop-header .header-top-container {
     background-color:#290789;
     box-shadow:none;
}
/*Shift the category and search column down to align with the logo*/
.category-column, .search-column {
     margin-top:80px;
}
/*Style the search column*/
.search-column .input-text, .mobile-search-column .input-text{
     border:2px solid #000;
     border-radius:5px;
}
.search-column .button-search, .mobile-search-column .button-search{
     background-color:#000;
}
.search-column .fa-search, .mobile-search-column .fa-search{
     color:#fff;
}
/*Add margins in between the elements of nav menu*/
.category-column #nav {
     display:flex;
     width:100%;
}
.category-column #nav > li.level0 {
     margin:0 auto;
}
/*Reduce the logo for 1199px and less*/
@media only screen and (min-width:960px) and (max-width:1199px) {
     .logo img {
         max-width:180px;
    }
}
/*Reduce the font size of nav menu*/
@media only screen and (min-width:960px) and (max-width:1259px) {
     .nav-regular li.level0 > a > span {
         font-size:14px;
    }
}
/*hide-below-960/hide-above-960 doesn't work in all cases, so add the code but not for the mini cart*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
@media only screen and (max-width:767px) {
     .xm-grid-header .container {
         padding:0 !important;
    }
}
/*For mobile, the logo within the wrapper should occupy full width*/
@media only screen and (max-width:767px) {
     .mobile-header .header .logo {
         width:100%;
    }
}
/*Remove the background color coming from the Style.css*/
.mobile-header .switch-cust-col .header-top-container {
     background-color:#290789;
}
.mobile-header .header-multi-column .header-top-container {
     background-color:#fff;
}
.mobile-header .logo img {
     max-width:100px;
}
/*Reduce the width to accomodate other elements on one line for mobile devices*/
@media only screen and (max-width:479px) {
     .mobile-header .switch-cust-col .item-switch-customer {
         width:75% !important;
         text-align:left;
    }
     .mobile-header .switch-cust-col .item-menu-icon {
         width:20% !important;
    }
}
/*Color to the fa-bars of category menu*/
.mobile-header .item-menu-icon:not(.category-icon) .mmenu-category-wrapper .fa-bars, .mobile-header .item-min-cart:not(.cart-icon) .fa-shopping-cart {
     color:#290789 !important;
}
/*Shift the second header up*/
.mobile-header .header-multi-column .module-header-multi {
     margin-top:-10px;
}
/*Add margins on both the sides of logo*/
.mobile-header .item-logo {
     margin-left:20px !important;
     margin-right:20px !important;
}
/*Search column for mobile*/
.mobile-search-column {
     width:95% !important;
     margin:-10px 10px 0 10px;
}
/*Add a border below mobile header*/
.mobile-header {
     border-bottom:1px solid #290789;
     padding-bottom:10px;
}
/*Specifically for tablet devices*/
@media only screen and (max-width:479px){
     .category-icon, .cart-icon {
         display:none !important;
    }
}
 @media only screen and (min-width:480px) and (max-width:959px) {
     .mobile-header .header-multi-column .item-menu-icon, .mobile-header .header-multi-column .item-min-cart {
         display:none !important;
    }
     /*Reduce the width of columns*/
     .mobile-header .header-multi-column {
         width:30% !important;
    }
     .mobile-header .mobile-search-column {
         width:60% !important;
         float:right;
         margin-top:-80px;
         margin-right:30px;
    }
     /*Add some padding on the top header*/
     .mobile-header .switch-cust-col .header-top {
         padding-top:5px;
         padding-bottom:5px;
    }
     /*Remove scrollbars*/
     .mobile-header {
         overflow-x:hidden;
    }
     /*Display the basket summary*/
     .mobile-header, .mobile-header .switch-cust-col, .mobile-header .switch-cust-col .module-header-multi {
         overflow:unset;
    }
}